import React from 'react'
import { PREVIOUS_BOOKS_SECTIONS } from '../lib/constants'

import { Grid, Header } from 'semantic-ui-react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Book from '../components/book'
import Button from '../components/button'

import './previous-books.css'

const PreviousBooksPage = () => (
  <Layout>
    <SEO title="Previous Books" />

    <Grid className="previous-books-page" stackable columns="equal" centered>
      <Grid.Column>
        <Book bookIndex={1} />
      </Grid.Column>
      <Grid.Column>
        <Book bookIndex={2} />
      </Grid.Column>
      <Grid.Column>
        <Book bookIndex={3} />
        
        <div className="buttons">
          {PREVIOUS_BOOKS_SECTIONS.map(buttonSection => (
            <>
              <Header as="h6">{buttonSection.sectionTitle}</Header>
              {buttonSection.buttons.map(button => (
                <>
                  <Button href={button.href}>{button.title}</Button>
                  <br />
                </>
              ))}
            </>
          ))}
        </div>

      </Grid.Column>
    </Grid>

  </Layout>
)

export default PreviousBooksPage
